.footer_wrapper {
    width: 100%;
    background-color: var(--purple1);
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: end;

    .section_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;

        img {
            height: 60px;
        }

        span {
            font-size: 0.5em;
            color: #AE76BF;
        }
    }
}