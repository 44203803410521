.main_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    section {
        display: flex;
        justify-content: center;
    }
    
    .banner {
        width: 100%;
        background-color: #ffffff;
        opacity: 1;
        background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 18px ), repeating-linear-gradient( #fff8de55, #fff8de );
        
        .section_wrapper {
            display: grid;
            max-width: 1200px;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 1.5em;
            width: 100%;
            justify-items: center;
            align-items: center;

            .content {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-self: start;
                color: var(--purple3)
            }
    
            .image_container {
                width: 100%;
                display: flex;
                justify-content: center;
                
                img {
                    width: 100%;;
                    height: auto;
                    max-width: 385px;
                    max-height: 385px;
                    //border: 10px solid var(--purple3);
                    border-radius: 10px;
                    box-shadow: 0px 0px 0px 10px var(--purple3);    
                }
    
            }
        }
    }

    .work {
        background-color: var(--yellow1);
        width: 100%;

        .section_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1200px;
    
            .content {
                width: 100%;
                text-align: start;
                color: var(--yellow2)
            }
    
            .projects_container {
                display: grid;
                grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
                gap: 1em 1.5em;
                width: 100%;
                margin-top: 1em;
    
                .project {
                    display: flex;
                    flex-direction: column;
                    border-radius: 20px;
                    border: 10px solid var(--yellow2);
                    background-color: #ffffff;
                    overflow: hidden;
                    cursor: pointer;
                    transition: scale 0.5s;

                    &:hover {
                        box-sizing: content-box;
                        scale: 1.05;
                    }
    
                    img {
                        width: 100%;
                        object-fit: cover;
                        aspect-ratio: 7/4;
                        margin-bottom: 1em;
                    }
    
                    .project_title {
                        font-size: 1.5em;
                        font-weight: bold;
                        color: var(--yellow2);
                        margin: 0 0.667em;
                    }
    
                    .project_description {
                        font-size: 1em;
                        color: var(--yellow3);
                        margin: 1em;
                        
                    }
                }
            }
        }
        
    }

    .contact {
        width: 100%;
        background-color: #DDCDEA;
        background: linear-gradient(180deg, rgba(255,237,166,1) 0%, rgba(221,205,234,1) 60%, rgba(221,205,234,1) 100%);

        .section_wrapper {
            display: grid;
            max-width: 1200px;
            grid-template-columns: repeat(auto-fit, minmax(1fr));
            gap: 1.5em;

            .image_container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: start;
                color: var(--purple2);
            }
        }
        
    }
}