.navbar_wrapper { 
    display: flex; 
    justify-content: center;   
    height: 100px;
    width: 100%;
    background-color: #ffffff;
    padding: 0 1em;
    z-index: 2;
    box-shadow: 0 0.5px 20px var(--purple3);

    .section_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(min(100%, 1200px));
        
    }

    .logo_wrapper {
        display: flex;
        height: 90px;

        img {
            height: 100%;
        }
    }

    .link_wrapper {
        display: flex;
        justify-content: center;
        color: var(--purple3);

        li {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.5em;

            img {
                height: 40;
                filter:  invert(41%) sepia(12%) saturate(1170%) hue-rotate(227deg) brightness(93%) contrast(88%);
                transition: filter 0.5s;

                &:hover {
                    color: var(--purple2);
                    filter: invert(87%) sepia(33%) saturate(7499%) hue-rotate(220deg) brightness(81%) contrast(80%);
                }
            }
        }
    }
}