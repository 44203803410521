* {
    box-sizing: border-box;
    --yellow1: #FFEDA6;
    --yellow2: #C17559;
    --yellow3: #7A3300;
    --purple1: #DDCDEA;
    --purple2: #AE76BF;
    --purple3: #735F88;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
}

body {
    height: 100vh;
    margin: 0;
    background-color: #ffffff;

    h1 {
        font-size: 40px;
        font-weight: bold;
        margin: 0;
    }

    h2 {
        font-size: 28px;
        font-weight: bold;
        margin: 0.5em 0 1em;
    }

    p {
        font-size: 20px;
    }

    span {
        font-size: 10px;
    }
}

.app_wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;

    section {
        padding: 2em 3em;
    }

    

}